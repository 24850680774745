import React from 'react';
import CustomButton from '../CustomButton';
import { Link } from 'react-router-dom';

const LaserCardsPlan = () => {
  return (
    <div className="relative max-w-[1440px] mx-auto flex flex-col lg:flex-row gap-[45px] lg:gap-0 h-full pt-[68px] lg:pt-[136px] pb-[60px] px-[18px] lg:px-[161px]">
      <div className="lg:sticky lg:top-[10%] w-full h-full">
        <h3 className="text-secondary text-title text-center lg:text-start uppercase">Who Can Use LaserCards?</h3>
        <h2 className="lg:w-[351px] text-headline-1 pt-[11px] text-center lg:text-start">Choose your preferred option</h2>
        <p className="lg:w-[435px] text-body pt-[8px] text-center lg:text-start">
          Our virtual cards are designed to cater to a wide range of needs, and this includes individuals, firms, and businesses.
        </p>
        <div className="flex justify-center items-center lg:justify-start lg:items-start">
          <Link to="/plans">
            <CustomButton
              title="See Our Pricing"
              forwardIcon="/assets/forward.svg"
              textStyles="text-white text-button"
              containerStyles="bg-primary px-[47px] py-[16px] mt-[36px] rounded-full"
            />
          </Link>
        </div>
      </div>
      <div className="">
        <div className="flex flex-col">
          <div className="flex gap-[21px]">
            <p className="text-headline-2">Lasercards</p>
            <span className="bg-primary rounded-full px-[12px] py-[5px] text-white">Basic</span>
          </div>
          <p className="lg:w-[389px] text-body pt-[8px]">With Lasercard Basic, you can customize your cards, withdraw funds, and do more</p>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Enhanced Security</p>
          </div>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Shop globally</p>
          </div>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Easy to create your virtual card</p>
          </div>
          <img src="/assets/laserBasic.svg" alt="basic-plan" width={470} height={493} className="mt-[35px]" />
        </div>
        <div className="flex flex-col pt-[48px]">
          <div className="flex gap-[21px]">
            <p className="text-headline-2">Lasercards</p>
            <span className="bg-secondary rounded-full px-[12px] py-[5px] text-white">Pro</span>
          </div>
          <p className="lg:w-[389px] text-body pt-[8px]">
            Our LaserCards Pro is specifically designed to give you more for less. Do more with LaserCards Pro.
          </p>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Customizable Limits</p>
          </div>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Multiple Card Creation</p>
          </div>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Expense Tracking</p>
          </div>
          <img src="/assets/laserPro.svg" alt="basic-plan" width={470} height={493} className="mt-[35px]" />
        </div>
        <div className="flex flex-col pt-[48px]">
          <div className="flex gap-[21px]">
            <p className="text-headline-2">Lasercards</p>
            <span className="bg-green rounded-full px-[12px] py-[5px] text-white">Business</span>
          </div>
          <p className="lg:w-[389px] text-body pt-[8px]">
            You can open your virtual card with just one verification and have unlimited access to transact everywhere and anywhere.
          </p>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">All Pro features inclusive</p>
          </div>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Issue card To employees</p>
          </div>
          <div className="flex gap-[6px] pt-[18px]">
            <img src="/assets/check-circle.svg" alt="check icon" width={20} height={20} />
            <p className="text-body-0">Lock & secure funds</p>
          </div>
          <img src="/assets/laserBusiness.svg" alt="basic-plan" width={470} height={493} className="mt-[35px]" />
        </div>
      </div>
    </div>
  );
};

export default LaserCardsPlan;
