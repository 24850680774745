import React from 'react';
import CustomButton from '../CustomButton';
import { testimonialCardLeft, testimonialCardRight } from '../../constants';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

const Testimonials = () => {
  return (
    <div className="relative w-full mx-auto overflow-hidden pt-[79px] pb-[96px]">
      <div className="px-[18px] lg:px-[161px]">
        <h3 className="text-secondary text-title text-center lg:text-start">TESTIMONIALS</h3>
        <h2 className="lg:w-[351px] text-headline-1 text-center lg:text-start">See what people are saying</h2>
      </div>
      <Marquee direction="left" speed={20} delay={5}>
        <div className=" flex gap-[24px] px-[18px] mt-[20px]">
          {testimonialCardLeft.map(({ id, content, image, name }) => (
            <div key={id} className="bg-white px-[16px] py-[28px] border border-gray-200 rounded-lg">
              <p className="w-[352px] text-body text-dark-01">{content}</p>
              <div className="flex gap-[6px] pt-[16px]">
                <img src={image} alt={name} width={32} height={32} />
                <div>
                  <p className="text-body text-black pt-[5px]">{name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Marquee>
      <Marquee direction="right" speed={20} delay={5}>
        <div className="flex gap-[24px] px-[18px] mt-[24px]">
          {testimonialCardRight.map(({ id, content, image, name }) => (
            <div key={id} className="bg-white px-[16px] py-[28px] border border-gray-200 rounded-lg">
              <p className="w-[352px] text-body text-dark-01">{content}</p>
              <div className="flex gap-[6px] pt-[16px]">
                <img src={image} alt={name} width={32} height={32} />
                <div>
                  <p className="text-body text-black pt-[5px]">{name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Marquee>
      <div className="flex justify-center items-center">
        <Link to="/">
          <CustomButton
            title="Get Started for Free"
            forwardIcon="/assets/forward.svg"
            textStyles="text-white text-button"
            containerStyles="bg-primary px-[47px] py-[16px] mt-[36px] rounded-full"
          />
        </Link>
      </div>
    </div>
  );
};

export default Testimonials;
