import React, { useState } from 'react';

interface FaqItem {
  id: number;
  question: string;
  answer: string;
}

interface AccordionProps {
  faqData: FaqItem[];
}

const Accordion = ({ faqData }: AccordionProps) => {
  const [clicked, setClicked] = useState<number | null>(null);

  const toggle = (id: number) => {
    if (clicked === id) {
      return setClicked(null);
    }

    setClicked(id);
  };

  return (
    <div className="">
      {faqData.map(({ id, question, answer }) => (
        <div key={id} className="border-b">
          <div className="flex justify-between cursor-pointer py-[15px]" onClick={() => toggle(id)} key={id}>
            <h2 className="w-[360px] md:w-full text-start text-headline-3">{question}</h2>
            <span>
              {clicked === id ? (
                <img src="/assets/minus.svg" alt="" width={20} height={20} />
              ) : (
                <img src="/assets/plus.svg" alt="" width={20} height={20} />
              )}
            </span>
          </div>
          {clicked === id ? <p className={`text-start text-body lg:w-[431px]`}>{answer}</p> : null}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
