import React, { useEffect, useState } from 'react';
import { signupAnimation } from '../../../constants';
import SignupAccordion from './SignupAccordion';

const SignupProcess = () => {
  const [slideImage, setSlideImage] = useState<string | any>();

  useEffect(() => {
    let currentImageIndex = 0;

    const slide = setInterval(() => {
      setSlideImage(signupAnimation[currentImageIndex]);
      currentImageIndex = (currentImageIndex + 1) % signupAnimation.length;
    }, 4000);

    return () => clearInterval(slide);
  }, []);

  return (
    <div className="relative max-w-[1440px] mx-auto h-full pt-[68px] lg:pt-[136px] pb-[60px] px-[18px] lg:px-[161px]">
      <div className="flex flex-col lg:flex-row lg:justify-between pb-[60px]">
        <div className="px-[9px] lg:px-0 lg:w-[405px]">
          <h3 className="text-secondary text-title text-center lg:text-start">EASY SIGNUP PROCESS</h3>
          <h2 className="lg:w-[324px] text-headline-1 pt-[19px] text-center lg:text-start">Start exploring today</h2>
          <p className="lg:w-[405px] text-dark-01 text-body pt-[8px] pb-[32px]">
            Joining our community is easy, simply follow these steps to get started!
          </p>
          <div>
            <SignupAccordion />
          </div>
        </div>
        <div className="h-full pt-[30px] lg:pt-0">{slideImage && <img src={slideImage} alt="basic-info" width={500} height={500} />}</div>
      </div>
    </div>
  );
};

export default SignupProcess;
