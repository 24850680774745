import React from 'react';
import { Footer, Header } from '../../components';
import CustomButton from '../../components/CustomButton';
import Accordion from '../../components/LandingPage/FAQ/Accordion';
import config from '../../config';
import { supportFaqAccordion } from '../../constants';
import { Link } from 'react-router-dom';

export default function Support() {
  return (
    <div>
      <Header />
      <div>
        <div className="bg-graph bg-cover">
          <div className="flex flex-col justify-center items-center px-[18px] pt-[83px] lg:pt-[147px] pb-[48.38px] lg:pb-[130px]">
            <p className="text-secondary text-title text-center uppercase">contact us</p>
            <h1 className="lg:w-[485px] text-headline text-center pt-[19px]">Dedicated support at your service</h1>
            <p className="lg:w-[339px] text-body text-dark-01 text-center pt-[21px]">
              We are committed to serving you and our support team is available 24/7 to assist you with concerns or questions concerning our
              product.
            </p>
            <div>
              <Link to="/auth">
                <CustomButton
                  title="Get Started for Free"
                  forwardIcon="/assets/forward.svg"
                  textStyles="text-white text-button"
                  containerStyles="bg-primary px-[47px] py-[16px] mt-[45px] rounded-full"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center px-[18px]">
          <div className="flex flex-col md:flex-row gap-[16px]">
            <div className="bg-white-02 pt-[61px] pb-[24px] rounded-lg">
              <img src="/assets/messageLink.svg" alt="message icon" width={500} height={500} />

              <div className="pt-[18px] px-[20px] lg:px-[40px]">
                <h2 className="text-headline-3">Email Address</h2>

                <Link to="mailto:support@lasercards.co">
                  <p className="text-body pt-[8px]">support@lasercards.co</p>
                </Link>
              </div>
            </div>

            <div className="bg-card2 pt-[61px] pb-[24px] rounded-lg">
              <img src="/assets/phoneLink.svg" alt="message icon" width={500} height={500} />

              <div className="pt-[18px] px-[20px] lg:px-[40px]">
                <h2 className="text-headline-3">Social Media Handles</h2>
                <ul className="flex gap-[28px] text-body pt-[8px] underline underline-offset-2">
                  <li className="hidden">
                    <Link to="/">
                      <p>Facebook</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={config.socials.X}>
                      <p>X</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={config.socials.instagram}>
                      <p>Instagram</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={config.socials.threads}>
                      <p>Threads</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="relative max-w-[1440px] mx-auto flex flex-col lg:flex-row lg:justify-between pt-[68px] lg:pt-[89px] px-[18px] lg:px-[161px] pb-[69px]">
          <div>
            <h2 className="lg:w-[351px] text-headline-1 text-center lg:text-start">Frequently asked questions</h2>
          </div>
          <div className="lg:w-[551px] pt-[30px] lg:pt-0">
            <Accordion faqData={supportFaqAccordion} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
