import React from 'react';
import CustomButton from '../CustomButton';
import { footerLinks, socialLinks } from '../../constants';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="relative bg-tertiary sm:px-16 px-[18px] pb-[36px]">
      <img src="../../assets/rightAngle1.svg" alt="footer icon" width={200} height={200} className="absolute left-0" />
      <img src="../../assets/rightAngle2.svg" alt="footer icon" width={200} height={200} className="absolute right-0 top-[45%]" />

      <div className="flex flex-col justify-center items-center pt-[75px] pb-[64px] border-b border-white-02 border-opacity-20">
        <p className="text-secondary text-title">GET STARTED TODAY</p>
        <h2 className="lg:w-[494px] text-white text-headline-1 text-center pt-[11px]">Join the LaserCards Community</h2>
        <p className="lg:w-[433px] text-dark-02 text-center pt-[8px]">
          Connect with like-minded individuals, share experiences, and stay updated on the latest features and promotions within the
          LaserCards community.
        </p>
        <div className="position-relative z-[5]">
          <Link to="/auth">
            <CustomButton
              title="Get Started for Free"
              forwardIcon="/assets/forward.svg"
              textStyles="text-white text-button"
              containerStyles="bg-primary px-[47px] py-[16px] mt-[56px] rounded-full"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center pt-[42px]">
        <Link to="/">
          <img src="/assets/logoWhite.svg" alt="logo icon" width={130} height={31} />
        </Link>
        <div></div>
        <ul className="grid grid-cols-3 md:grid-cols-7 gap-[30px] pt-[36px]">
          {footerLinks.map(({ id, title, url }) => (
            <li key={id} className="text-start md:text-center">
              <Link to={url}>
                <p className="text-white text-body-2">{title}</p>
              </Link>
            </li>
          ))}
        </ul>
        <p className="lg:w-[603px] text-dark-02 text-body-2 text-center pt-[20px]"> ©{currentYear} LaserCards Ltd. </p>
        <div className="flex gap-[18px] mt-[26px]">
          {socialLinks.map(({ id, image, title, url }) => (
            <div key={id}>
              <Link to={url}>
                <img src={image} alt={title} width={20} height={20} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
